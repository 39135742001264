





























































































import Vue from "vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  APP_ASSESSMENTS,
  APP_ASSESSMENT_RESULT
} from "@/store/modules/common/constants";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { GET_COMPANY_DETAILS } from "@/store/modules/auth/constants";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "InceptionCandidateDashboard",
  components: {
    HelloUser
  },
  data() {
    return {
      loading: true,
      english_assessment: false, // check english assessment is attempted or not
      annotation_assessment: false, // check annotation assessment is attempted or not
      is_english_assessment_cancelled: false,
      is_annotation_assessment_cancelled: false
    };
  },
  async mounted() {
    // Find English Assessment
    const english_version =
      this.get_company_details.s3_path.assessment_setting.candidates_english;
    const annotation_version =
      this.get_company_details.s3_path.assessment_setting.candidates_annotation;

    this.find_assessment(english_version, annotation_version);
  },
  computed: {
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    }),
    ...mapGetters("auth", {
      get_company_details: GET_COMPANY_DETAILS
    })
  },
  methods: {
    ...mapActions("common", {
      app_assessment_result: APP_ASSESSMENT_RESULT
    }),
    ...mapMutations({
      set_root_notification: ROOT_NOTIFICATION
    }),
    async find_assessment(english_version: string, annotation_version: string) {
      let englishAssessmentExist = {} as AppAssessments.AssessmentResults;
      let annotationAssessmentExist = {} as AppAssessments.AssessmentResults;

      const get_assessments = async (
        english_version: string,
        annotation_version: string
      ) => {
        this.loading = true;

        if (englishAssessmentExist !== null) {
          const english_assessment = this.app_assessments.find(
            (val: AppAssessments.Assessment) =>
              val.assessment_type === english_version
          );
          // Check if english assessment is attempted or not
          if (english_assessment) {
            englishAssessmentExist = await this.app_assessment_result({
              assessment_id: english_assessment.id
            });
            if (englishAssessmentExist) {
              this.is_english_assessment_cancelled =
                englishAssessmentExist.status ===
                AppAssessments.AppAssessmentStatus.CANCELLED;
              this.english_assessment = true;
            }
          }
        }

        // Find Annotation Assessment
        if (!annotationAssessmentExist) return;
        const annotation_assessment = this.app_assessments.find(
          (val: AppAssessments.Assessment) =>
            val.assessment_type === annotation_version
        );

        // Check if annotation assessment is attempted or not
        if (annotation_assessment) {
          annotationAssessmentExist = await this.app_assessment_result({
            assessment_id: annotation_assessment.id
          });
          if (annotationAssessmentExist) {
            this.is_annotation_assessment_cancelled =
              annotationAssessmentExist.status ===
              AppAssessments.AppAssessmentStatus.CANCELLED;
            this.annotation_assessment = true;
          }
        }

        this.loading = false;
      };

      await get_assessments(english_version, annotation_version);
      // Check if english and annotation assessment version exist or not
      if (!englishAssessmentExist) {
        english_version =
          english_version === AppAssessmentNames.english_v1
            ? AppAssessmentNames.english_v2
            : AppAssessmentNames.english_v1;
      }
      if (!annotationAssessmentExist) {
        annotation_version =
          annotation_version === AppAssessmentNames.annotation_v1
            ? AppAssessmentNames.annotation_v2
            : AppAssessmentNames.annotation_v1;
      }
      if (!englishAssessmentExist || !annotationAssessmentExist) {
        englishAssessmentExist = {} as AppAssessments.AssessmentResults;
        annotationAssessmentExist = {} as AppAssessments.AssessmentResults;
        await get_assessments(english_version, annotation_version);
      }
    }
  }
});
